export type Consent = 'granted' | 'denied';

export const cookieType = {
  functionality_storage: 'functionality_storage',
  ad_storage: 'ad_storage',
  analytics_storage: 'analytics_storage',
  ad_user_data: 'ad_user_data',
  ad_personalization: 'ad_personalization',
} as const;

export type CookieType = keyof typeof cookieType;

export type ConsentObject = Record<CookieType, Consent>;

export type CookieSelection = Array<CookieType>;

export type ScrollablePaths = Array<string>;

export type CookieBannerProps = {
  onSave: (consent: ConsentObject) => void;
  /**
   * Pass an array with the path names where the user should be able to scroll
   */
  allowScrollOnPaths?: ScrollablePaths;
};

/* Generated file. Do not modify. */
import { createElement } from 'react';
import { IconWrapper } from '../IconWrapper';
import type { IconWrapperProps } from '../IconWrapper';
export default (props: IconWrapperProps) =>
  createElement(
    IconWrapper,
    props,
    <path
      fill="currentColor"
      d="M13 4a1 1 0 1 0 0-2v2Zm9 9a1 1 0 1 0-2 0h2Zm-3.334-9.428-.707-.707.707.707ZM20.047 3V2v1Zm-7.365 12.08.243.97a1 1 0 0 0 .464-.264l-.707-.707ZM9 16l-.97-.242a1 1 0 0 0 1.213 1.212L9 16Zm.92-3.682-.707-.707a1 1 0 0 0-.263.464l.97.243ZM20 19a1 1 0 0 1-1 1v2a3 3 0 0 0 3-3h-2Zm-1 1H5v2h14v-2ZM5 20a1 1 0 0 1-1-1H2a3 3 0 0 0 3 3v-2Zm-1-1V5H2v14h2ZM4 5a1 1 0 0 1 1-1V2a3 3 0 0 0-3 3h2Zm1-1h8V2H5v2Zm15 9v6h2v-6h-2Zm-.627-8.72a.953.953 0 0 1 .674-.28V2c-.783 0-1.534.311-2.088.865l1.414 1.414Zm.674-.28c.253 0 .495.1.674.28l1.414-1.415A2.953 2.953 0 0 0 20.047 2v2Zm.674.28c.179.178.279.42.279.673h2c0-.783-.311-1.534-.865-2.088l-1.414 1.414Zm.279.673c0 .253-.1.495-.28.674l1.415 1.414A2.953 2.953 0 0 0 23 4.953h-2Zm-.28.674-8.745 8.745 1.415 1.415 8.745-8.746-1.414-1.414Zm-8.28 8.482-3.683.92.486 1.941 3.682-.92-.485-1.94Zm-2.47 2.133.92-3.682-1.94-.485-.92 3.683 1.94.485Zm.658-3.217 8.745-8.746-1.414-1.414-8.746 8.745 1.415 1.415Z"
    />
  );

import { ScrollablePaths } from '../../cookieBanner.model';

export const getAllowPageScroll = (
  allowScrollOnPaths?: ScrollablePaths,
): boolean => {
  if (typeof window === 'undefined') return false;

  if (!allowScrollOnPaths || !allowScrollOnPaths.length) return false;

  return allowScrollOnPaths.includes(window.location.pathname);
};
